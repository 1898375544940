<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <h3 class="mt-5">
                    Your order has been canceled. Please wait while we redirect
                    you...
                </h3>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CartCheckoutCancelView",
    data() {
        return {
            timeout: null,
        };
    },
    mounted() {
        this.timeout = window.setTimeout(() => {
            this.$router.push({ name: "order", query: this.$route.query });
        }, 3000);
    },
    unmounted() {
        if (this.timeout) {
            window.clearTimeout(this.timeout);
        }
    },
};
</script>

<style scoped></style>
