import { defineStore } from "pinia";
import api from "@/lib/axios/http-client";

export const useAuthStore = defineStore("auth", {
    state: () => {},

    actions: {
        attemptLogin(order_id, email) {
            return api.login(order_id, email);
        },
        oneClickLogin(token) {
            return api.oneClickLogin(token);
        },
        logOut() {
            return api.logout();
        },
        isLoggedIn() {
            return api.isLoggedIn();
        },
    },
});
