<template>
    <header>
        <div class="container" id="order_page_header">
            <div class="grid-rows-2 items_center">
                <div class="grid-col">
                    <div class="logo">
                        <picture>
                            <source
                                media="(min-width:541px)"
                                srcset="/images/logo190.svg"
                            />
                            <source
                                media="(max-width:540px)"
                                srcset="/images/mobile-logo160.svg"
                            />
                            <img
                                src="/images/logo.svg"
                                alt="TurnMeRoyal"
                                style="width: auto"
                            />
                        </picture>
                    </div>
                </div>
                <div class="grid-col">
                    <div class="float-right header_right grid-rows-2">
                        <div class="greetings_customer">
                            <span>Hi,</span>
                            {{
                                orderStore?.order_data?.customer_data
                                    ?.clientName
                            }}
                        </div>
                        <div @click.prevent="handleLogout" class="logout">
                            Log out
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { useOrderStore } from "@/stores/order";
import { useAuthStore } from "@/stores/auth";

export default {
    name: "HeaderPartial",
    data() {
        return {
            orderStore: useOrderStore(),
            authStore: useAuthStore(),
        };
    },
    methods: {
        handleLogout: async function () {
            this.$Progress.start();
            await this.authStore.logOut();
            this.$Progress.finish();
            this.$router.push({ name: "login" });
        },
    },
};
</script>

<style scoped></style>
