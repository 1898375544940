<template>
    <div id="login-content">
        <div class="background">
            <div id="login_block" class="absolute-center-block">
                <div id="logo">
                    <picture>
                        <source
                            media="(min-width:541px)"
                            srcset="/images/logo.svg"
                        />
                        <source
                            media="(max-width:540px)"
                            srcset="/images/mobile_logo.svg"
                        />
                        <img
                            src="/images/logo.svg"
                            alt="TurnMeRoyel"
                            style="width: auto"
                        />
                    </picture>
                </div>
                <div id="login-form">
                    <form
                        class="form-horizontal"
                        method="POST"
                        id="login_form_verify"
                        @submit.prevent="LoginSubmit"
                    >
                        <fieldset :disabled="loading">
                            <div class="form-group">
                                <div>
                                    <label for="order_number_input"
                                        >Order number</label
                                    >
                                </div>
                                <input
                                    name="order_number_input"
                                    id="order_number_input"
                                    type="text"
                                    required
                                    minlength="4"
                                    v-model="orderID"
                                    placeholder="#0000"
                                />
                            </div>
                            <div class="form-group">
                                <div>
                                    <label for="email_input">Email</label>
                                </div>
                                <input
                                    type="email"
                                    required
                                    name="email_input"
                                    id="email_input"
                                    v-model="orderMAIL"
                                    @keyup="isValidEmail"
                                    placeholder="example@turnmeroyal.com"
                                />
                            </div>
                            <div class="form-group-button">
                                <p class="errorMessage" v-if="errorMessage">
                                    {{ errorMessage }}
                                </p>
                                <input
                                    type="submit"
                                    :class="ButtonActive"
                                    value="Sign in"
                                />
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useAuthStore } from "@/stores/auth";
import { mapActions } from "pinia";

export default {
    name: "LoginPageView",
    data() {
        return {
            errorMessage: "",
            count: 0,
            orderIDValide: false,
            orderMAILValide: false,
            orderID: "",
            orderMAIL: "",
            loading: false,
        };
    },
    watch: {
        orderID: function () {
            this.isValidID();
            this.addSymbol("#");
        },
        orderMAIL: function () {
            this.isValidEmail();
        },
    },
    computed: {
        ButtonActive() {
            return !this.orderMAILValide || !this.orderIDValide || this.loading
                ? ""
                : "active";
        },
    },
    methods: {
        ...mapActions(useAuthStore, ["attemptLogin"]),
        //Checking form Order ID validation
        isValidID: function () {
            this.orderIDValide = this.orderID.length > 3;
        },
        addSymbol: function (symbol) {
            if (this.orderID.length >= 1 && !this.orderID.includes(symbol)) {
                this.orderID = symbol + this.orderID;
            }
        },
        //Checking Email validation
        isValidEmail: function () {
            this.orderMAILValide = this.validEmail(this.orderMAIL);
        },
        //Email validation structure
        validEmail: function (email) {
            var re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        //Send ajax on clicked login button
        LoginSubmit: function () {
            this.$Progress.start();
            this.loading = true;

            this.attemptLogin(this.orderID, this.orderMAIL)
                .then(() => {
                    if (this.$route.query.redirect) {
                        this.$router.replace(this.$route.query.redirect);
                    } else {
                        this.$router.push({ name: "order" });
                    }
                })
                .catch(() => {
                    this.$Progress.fail();
                    this.loading = false;
                    this.errorMessage = "Order number or email does not exist";
                });
        },
    },
};
</script>

<style scoped></style>
