<template>
    <div class="images_slider_block">
        <div v-for="slide in slides" :key="slide">
            <expandable-image :src="slide" :frame="frame" />
            <img
                src="@/assets/images/upsells/additional_copy/one_extra_copy_portrait.png"
                alt="5 years warranty"
                v-if="copy_purchased"
                class="additional-copy-image"
            />
            <img
                src="@/assets/images/upsells/warranty/5_years_warranty_portrait.png"
                alt="5 years warranty"
                v-if="warranty"
                class="warranty-image"
            />
        </div>
    </div>
</template>

<script>
import ExpandableImage from "./ExpandableImage.vue";

//var ZoomComponent = Vue.component('zoom-on-hover', ZoomOnHover);
export default {
    name: "ImageSlider",
    components: { ExpandableImage },
    props: ["slides", "frame", "warranty", "copy_purchased"],
};
</script>

<style lang="scss" scoped>
.images_slider_block {
    position: relative;
}

.additional-copy-image {
    position: absolute;
    right: -15px;
    top: 25%;
    width: 50px;

    @media (min-width: 768px) {
        left: 30%;
        top: calc(100% - 38px);
        width: 60px;
    }
}

.warranty-image {
    position: absolute;
    right: -15px;
    top: calc(25% + 60px);
    width: 50px;

    @media (min-width: 768px) {
        left: 55%;
        top: calc(100% - 38px);
        width: 60px;
    }
}
</style>
