import axios from "axios";
import appConfig from "@/config/app";
import {
    setAuthTokens,
    clearAuthTokens,
    isLoggedIn,
    applyAuthTokenInterceptor,
    getRefreshToken,
    getBrowserLocalStorage,
} from "axios-jwt";

let client = axios.create({
    baseURL: appConfig.base_url,
    headers: {
        post: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    },
});

const requestRefresh = (refresh_token) => {
    // Notice that this is the global axios instance, not the axiosInstance!  <-- important
    // try {
    return axios
        .post(`${appConfig.base_url}/api/v1/auth/refresh_token`, {
            refresh_token,
        })
        .then((response) => {
            return {
                accessToken: response.data.access_token,
                refreshToken: response.data.refresh_token,
            };
        })
        .catch(() => {
            clearAuthTokens();
            window.location.reload();
        }); // TODO: Figure out how to return 401 response if refresh token has expired
};

applyAuthTokenInterceptor(client, {
    requestRefresh,
    getStorage: getBrowserLocalStorage,
}); // Notice that this uses the axiosInstance instance.  <-- important

const login = (order_id, email) => {
    return client
        .post("/api/v1/auth/login", { order_id, email })
        .then((response) => {
            // save tokens to storage
            setAuthTokens({
                accessToken: response.data.access_token,
                refreshToken: response.data.refresh_token,
            });
        });
};

const oneClickLogin = (token) => {
    return client
        .post("/api/v1/auth/one-click-login", { token })
        .then((response) => {
            // save tokens to storage
            setAuthTokens({
                accessToken: response.data.access_token,
                refreshToken: response.data.refresh_token,
            });
        });
};

const logout = async function () {
    await client.post("/api/v1/auth/logout", {
        refresh_token: getRefreshToken(),
    });
    clearAuthTokens();
};

export default {
    client,
    login,
    oneClickLogin,
    logout,
    isLoggedIn,
};
