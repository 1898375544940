import { createRouter, createWebHistory } from "vue-router";
import LoginPageView from "@/views/auth/LoginPageView.vue";
import PageNotFoundView from "@/views/errors/PageNotFoundView.vue";
import OrderPageView from "@/views/OrderPageView.vue";
import { useAuthStore } from "@/stores/auth";
import CartCheckoutSuccessView from "@/views/cart/CartCheckoutSuccessView.vue";
import CartCheckoutCancelView from "@/views/cart/CartCheckoutCancelView.vue";
import OneClickLoginView from "@/views/auth/OneClickLoginView.vue";

function prefixRoutes(prefix, routes) {
    return routes.map((route) => {
        route.path = prefix + "" + route.path;
        return route;
    });
}

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: "/",
            name: "login",
            component: LoginPageView,
        },
        {
            path: "/one-click-login",
            name: "one-click-login",
            meta: { requiresAuth: false },
            component: OneClickLoginView,
        },
        {
            path: "/order",
            name: "order",
            component: OrderPageView,
        },
        ...prefixRoutes("/cart", [
            {
                path: "/success",
                name: "cart.checkout",
                component: CartCheckoutSuccessView,
            },
            {
                path: "/cancel",
                name: "cart.cancel",
                component: CartCheckoutCancelView,
            },
        ]),
        {
            path: "/:pathMatch(.*)*",
            component: PageNotFoundView,
            meta: { requiresAuth: false },
        },
    ],
});

router.beforeEach((to, from, next) => {
    let authStore = useAuthStore();

    if (to.name === "login") {
        if (authStore.isLoggedIn()) {
            next({ name: "order" });
        } else {
            next();
        }
    } else if (to.meta && to.meta.requiresAuth === false) {
        next();
    } else if (authStore.isLoggedIn()) {
        next();
    } else {
        next({
            name: "login",
            query: { redirect: to.fullPath },
        });
    }
});

export default router;
