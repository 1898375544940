<template>
    <div class="revision_block" @click.stop="onClick">
        <div class="center_revision">
            <h2>Please describe what you’d like changed in {{ item }}</h2>
            <textarea
                v-model="inputValue"
                class="input"
                type="text"
                placeholder="Enter description here. Minimum 30 characters"
            ></textarea>
            <div
                class="button submit_button"
                :class="{ active: buttonIsActive }"
                @click.stop="submitForm"
            >
                Submit revision
            </div>
            <div
                @click.stop="$emit('close')"
                class="button button_no_bg cancel_button"
            >
                Cancel
            </div>
        </div>
    </div>
</template>

<script>
import { useOrderStore } from "@/stores/order";
import * as Sentry from "@sentry/vue";
import { useAuthStore } from "@/stores/auth";

export default {
    name: "RevisionModal",
    props: {
        item: String,
    },
    data() {
        return {
            opened: false,
            inputValue: "",
            loading: false,
            orderStore: useOrderStore(),
            authStore: useAuthStore(),
        };
    },
    computed: {
        buttonIsActive() {
            return this.inputValue.length > 29;
        },
    },
    emits: {
        revisionSent({ product_id, step, product_status }) {
            return product_id && step >= 0 && product_status;
        },
        close: () => true,
    },
    created() {
        document.addEventListener("keydown", this._onEscape);
    },
    unmounted() {
        document.removeEventListener("keydown", this._onEscape);
    },
    methods: {
        _onEscape(e) {
            if (e.keyCode === 27) {
                this.$emit("close");
            }
        },
        submitForm() {
            if (this.buttonIsActive) {
                this.sendRevision();
            }
        },
        onClick(e) {
            if (e.target.classList.contains("revision_block")) {
                this.$emit("close");
            }
        },
        sendRevision() {
            if (this.loading) return;

            this.loading = true;
            this.$Progress.start();

            this.orderStore
                .writeRevision(this.inputValue)
                .then(() => {
                    this.$Progress.finish();
                    this.$emit("close");
                })
                .catch((e) => {
                    this.$Progress.fail();

                    this.$toast.add({
                        severity: "error",
                        summary: "Error",
                        detail:
                            e?.response?.data ||
                            "There was a server error loading your order, please try again later!",
                        life: 5000,
                    });

                    if (e?.response?.status >= 500) {
                        Sentry.captureException(e);
                    } else {
                        this.authStore.logOut().then(() => {
                            this.$router.push({
                                name: "login",
                                query: {
                                    redirect: this.$route.fullPath,
                                },
                            });
                        });
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style scoped lang="scss"></style>
