<template>
    <span>
        <a
            href="#"
            class="info-icon-link"
            @click.prevent="() => (visible = !visible)"
            ><img
                src="@/assets/images/info-icon.svg"
                alt="More Info..."
                class="info-icon"
                :title="title"
            />
            <span v-if="label">&nbsp;{{ label }}</span></a
        >
        <Dialog
            v-model:visible="visible"
            modal
            :header="title"
            style="max-width: 500px; margin: auto 24px"
        >
            <template #closeicon>
                <img src="/images/closebtn.svg" alt="" />
            </template>
            <div class="mb-4">
                <slot />
            </div>
        </Dialog>
    </span>
</template>

<script>
import Dialog from "primevue/dialog";

export default {
    name: "InfoPopoverComponent",
    components: {
        // eslint-disable-next-line vue/no-reserved-component-names
        Dialog,
    },
    props: {
        title: String,
        label: String,
    },
    data() {
        return {
            visible: false,
        };
    },
};
</script>

<style lang="scss">
.info-icon-link {
    font-size: 12px;
    color: #8d8b88;
    text-decoration: none;
    font-weight: normal;
    font-family: Helvetica, ui-sans-serif, system-ui, -apple-system,
        BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
        "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol", "Noto Color Emoji";

    &:hover,
    &:focus,
    &:active {
        color: #8d8b88;
        text-decoration: none;
    }
}

.info-icon {
    margin-top: -2px;
    margin-left: 2px;
}

.icons {
    display: flex;
    align-items: center;
}

.p-dialog {
    .p-dialog-header {
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-shrink: 0;
        text-align: center !important;

        .p-dialog-title {
            width: 100%;
        }
    }

    .p-dialog-header-icons {
        display: flex;
        align-items: center;
        position: relative;
        top: -30px;
        right: -30px;
    }
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 1rem;
    width: 1.5rem;
    color: #6c757d !important; //#6c757d;

    &.p-dialog-header-close {
        background: white !important;
        border: 1px solid rgba(0, 0, 0, 0.25) !important;
    }

    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}
</style>
