import { defineStore } from "pinia";
import api from "@/lib/axios/http-client";
import * as Sentry from "@sentry/vue";

export const useOrderStore = defineStore("order", {
    state: () => {
        return {
            order_data: null,
            selectedProduct: false,
        };
    },

    getters: {
        step: (state) => {
            return state.getOrderStep(state.selectedProduct) || 0;
        },
        currentOrder: (state) => {
            return state.order_data?.customer_data?.orders.find(
                (o) => o.id === state.selectedProduct
            );
        },
    },

    actions: {
        getOrder() {
            return api.client.get("/api/v1/order").then((response) => {
                this.order_data = response.data;

                let orderIds = response.data.customer_data.orders.map(
                    (o) => o.id
                );

                if (
                    !this.selectedProduct ||
                    !orderIds.includes(this.selectedProduct)
                ) {
                    this.selectedProduct = orderIds[0];
                }

                Sentry.setContext("order", {
                    order_id: response.data.customer_data.orderID,
                    order_region: response.data.order_region,
                    order_currency: response.data.order_currency,
                });

                return response;
            });
        },
        getOrderStep(order_id) {
            let el = this.order_data.steps_array.find(
                (el) => el.id === order_id
            );

            return el ? el.step : 0;
        },
        approveDesign() {
            return api.client
                .post("/api/v1/order/approve-design", {
                    product_id: this.selectedProduct,
                })
                .then((response) => {
                    this.order_data = response.data;

                    let change_to = this.order_data.steps_array.find(
                        (product) =>
                            product.step <= 3 && product.id !== "progressbar"
                    );

                    if (change_to) {
                        this.changeSelectedProduct(change_to.id);
                    }
                });
        },
        startPaidApproval() {
            return api.client.post("/api/v1/order/approve-design", {
                product_id: this.selectedProduct,
            });
        },
        writeRevision(text) {
            return api.client
                .post("/api/v1/order/send-revision", {
                    revision_text: text,
                    product_id: this.selectedProduct,
                })
                .then((response) => {
                    this.order_data = response.data;
                });
        },
        getPictureUpdateCheckout(form_data, config) {
            return api.client.post(
                "/api/v1/order/photo-replacement-checkout",
                form_data,
                config
            );
        },
        updatePicture(form_data, config) {
            return api.client
                .post("/api/v1/order/update-picture", form_data, config)
                .then((response) => {
                    this.order_data = response.data;
                });
        },
        updateFrame(record_id, value) {
            let order = this.order_data.customer_data.orders.find(
                (o) => o.id === record_id
            );
            order.frame = value;
        },
        updateSize(record_id, value) {
            let order = this.order_data.customer_data.orders.find(
                (o) => o.id === record_id
            );
            order.orderSize = value;
        },
        changeSelectedProduct(product_id) {
            this.selectedProduct = product_id;
        },
    },
});
