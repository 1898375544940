<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <h3 class="mt-5">
                    Thank you for your payment!<br />
                    It sometimes takes a minute or two for your payment to be
                    reflected in our system, and you may need to refresh your
                    page.<br />
                    If you still don't see your purchase, please contact us.<br />
                    <br />
                    Please wait while we redirect you...
                </h3>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CartCheckoutSuccessView",
    data() {
        return {
            timeout: null,
        };
    },
    mounted() {
        this.timeout = window.setTimeout(() => {
            this.$router.push({ name: "order", query: this.$route.query });
        }, 5000);
    },
    unmounted() {
        if (this.timeout) {
            window.clearTimeout(this.timeout);
        }
    },
};
</script>

<style scoped></style>
