export function stepToStatus(step) {
    if (step <= 3) {
        return "Waiting";
    } else if (step === 3.5) {
        return "Fixing";
    } else {
        return "Approved";
    }
}

function _matchDimensions(size) {
    return size.match(/^(\d+)\s?x\s?(\d+)/);
}
export function sizeToLandscape(size) {
    let matches = _matchDimensions(size);

    if (!matches || parseInt(matches[1]) > parseInt(matches[2])) {
        return size;
    }

    return matches[2] + " x " + matches[1];
}

export function sizeToPortrait(size) {
    let matches = _matchDimensions(size);

    if (!matches || parseInt(matches[1]) < parseInt(matches[2])) {
        return size;
    }

    return matches[2] + " x " + matches[1];
}

export function sizeIsPortrait(size) {
    let matches = _matchDimensions(size);

    if (!matches) {
        return false;
    }

    return parseInt(matches[1]) < parseInt(matches[2]);
}
