export default {
    color: "#d0c8ec",
    failedColor: "#874b4b",
    thickness: "7px",
    transition: {
        speed: "0.2s",
        opacity: "0.2s",
        termination: 300,
    },
    autoRevert: true,
    inverse: false,
};
