<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <LoadingSpinner></LoadingSpinner>
            </div>
        </div>
    </div>
</template>

<script>
import { useAuthStore } from "@/stores/auth";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
    name: "OneClickLoginView",
    components: { LoadingSpinner },
    data() {
        return {
            authStore: useAuthStore(),
        };
    },
    async mounted() {
        await this.authStore.logOut();
        let token = this.$route.query.token;

        if (!token) {
            this.$toast.add({
                severity: "warn",
                summary: "Error",
                detail: "Link not valid, please log in instead",
                life: 3500,
            });

            this.$router.push({ name: "login" });
        } else {
            this.authStore
                .oneClickLogin(token)
                .then(() => {
                    this.$router.push({ name: "order" });
                })
                .catch((e) => {
                    this.$toast.add({
                        severity: "warn",
                        summary: "Error",
                        detail: e?.response?.data,
                        life: 5000,
                    });
                    this.$router.push({ name: "login" });
                });
        }
    },
};
</script>

<style scoped></style>
