export function round(num, decimalPlaces = 0) {
    if (num < 0) return -round(-num, decimalPlaces);

    num = Math.round(num + "e" + decimalPlaces);
    return Number(num + "e" + -decimalPlaces);
}

export function currencySymbol(currency) {
    let currencySymbols = {
        USD: "$",
        EUR: "€",
        AUD: "AU$",
        CAD: "CA$",
        GBP: "£",
    };

    return currencySymbols[currency] || "";
}
