<template>
    <form @submit.prevent="handleUpload" class="form">
        <fieldset :disabled="formDisabled">
            <h2>Please upload a new photo</h2>

            <FileUpload
                name="image"
                :disabled="formDisabled"
                @select="handleSelect"
                @upload="false"
                :showUploadButton="false"
                :showCancelButton="false"
                :chooseLabel="chooseLabel"
                url="#"
                :multiple="true"
                :fileLimit="20"
                :auto="false"
                accept="image/png, image/gif, image/jpeg"
                :maxFileSize="6000000"
            >
                <template #empty> </template>
            </FileUpload>

            <input type="hidden" name="record_id" :value="order.id" />

            <div class="mt-2">
                <button type="submit" class="btn btn-tmr btn-sm button-submit">
                    UPLOAD YOUR PHOTO
                </button>

                <button
                    @click="$emit('close')"
                    class="btn btn-tmr-secondary btn-sm ml-1 button-close"
                >
                    Cancel
                </button>
            </div>
        </fieldset>
    </form>
</template>

<script>
import { defineComponent } from "vue";
import { useOrderStore } from "@/stores/order";
import FileUpload from "primevue/fileupload";
import * as Sentry from "@sentry/vue";

export default defineComponent({
    name: "PhotoReplacementDialogue",
    props: ["order"],
    emits: ["close"],
    components: { FileUpload },
    data() {
        return {
            formDisabled: false,
            orderStore: useOrderStore(),
            files: [],
        };
    },
    computed: {
        chooseLabel() {
            return this.files.length === 0
                ? "CLICK HERE TO ADD PHOTOS"
                : "ADD MORE PHOTOS";
        },
    },
    methods: {
        handleSelect(e) {
            this.files = e.files;
        },
        handleUpload(evt) {
            if (this.files.length === 0) return;

            let form = evt.target;
            let formData = new FormData(form);

            for (let i = 0; i < this.files.length; i++) {
                formData.append("images[]", this.files[i]);
            }

            this.formDisabled = true;
            this.$Progress.set(1);

            let config = {
                onUploadProgress: (pe) => {
                    if (pe.loaded === pe.total) {
                        this.$Progress.start();
                    } else {
                        this.$Progress.set((pe.loaded * 100) / pe.total);
                    }
                },
            };

            this.orderStore
                .updatePicture(formData, config)
                .then(() => {
                    this.$Progress.finish();
                })
                .catch((e) => {
                    this.$Progress.fail();
                    Sentry.captureException(e);
                    this.$toast.add({
                        severity: "warn",
                        summary: "Error",
                        detail: "There was a problem with your request. Please try again later.",
                        life: 5000,
                    });
                })
                .finally(() => {
                    this.$emit("close");
                    this.formDisabled = false;
                });
        },
    },
});
</script>

<style scoped lang="scss">
h2 {
    margin: 0.5rem 0;
}
div:deep(.p-fileupload-file-size) {
    display: none;
}

div:deep(.p-badge-warning.p-fileupload-file-badge) {
    font-size: 0px;
    line-height: 0px;

    &:after {
        font-size: 12px;
        line-height: 26px;
        content: "Ready to upload";
    }
}

div:deep(input[type="file"]) {
    display: none;
}

div:deep(.p-button) {
    cursor: pointer;
}
</style>
